import React, { useContext, useEffect, useState } from 'react'
import SelectCityModal from '../modals/SelectCityModal';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';
import AppServices from '../../services/general/appServices';

const SelectCity = observer(() => {
    const {app} = useContext(Context);
    const [openModal, setOpenModal] = useState();
    const [city, setCity] = useState();

    const fetchCities = async () => {
        const res = await AppServices.locations();
        console.log(res)
        if(res.statusCode === 200){
            const newArr = res.content.items.map(it =>{
                let temp = {
                    value: it.id,
                    label: it.title,
                    location: it.value
                }
                return temp
            })
            app.setAllCity(newArr);
            if(res.content.items?.length <= 0){
                localStorage.removeItem('city');
            }
        }else{
            localStorage.removeItem('city');
        }
    }

    useEffect(() => {
        fetchCities();
    }, [])

    useEffect(() => {
        if(app.allCities?.length >= 1){
            let temp = localStorage.getItem('city');
            try{
                temp = JSON.parse(temp);
                if((app.allCities?.find(it => it.value == temp?.value && it.label == temp?.label))){
                    app.setCity(temp);
                    setCity(temp)
                }else{
                    app.setCity(app.allCities[0]);
                    setCity(app.allCities[0]);
                    setOpenModal(true);
                }
            }catch(e){
                app.setCity(app.allCities[0]);
                setCity(app.allCities[0]);
                setOpenModal(true)
            }
        }
    }, [app.allCities])
    
  return (
    app.allCities?.length >= 1 &&
    <>
        <div className='select-city' onClick={() => setOpenModal(true)}>
            <i className='fa fa-map-marker'></i>
            {city?.label}
        </div>
        {openModal && <SelectCityModal show={openModal} setShow={setOpenModal} setCity={setCity}/>}
    </>
  )
})

export default SelectCity